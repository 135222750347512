// importing libraries
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch } from 'react-redux';
// importing components
import useAxios from './useAxios';
import { useSnackbar } from '../context/SnackbarContext';
import {
  removeExtractionDetails, setExtractionDetails, setSelectedDocumentId, removeSelectedDocumentId,
  resetCurrentPage, setCurrentPage,
  setIsDataSkipped,
  setEntityCount,
  setTableCount,
} from '../redux/actions/extractionActions';
import { ExtractionService } from '../services/constants/endpoints';
import { axiosCancelToken } from '../config/axios';
import { resetAllBbox, setAllBbox, setShowAllBbox } from '../redux/actions/boundingBoxActions';
import Toast from '../services/constants/toasters';

function useExtraction(applicationId, docId) {
  const axiosPrivate = useAxios();
  const dispatch = useDispatch();
  const { openSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const fetchDocumentExtraction = (
    pageNumber = 1,
    skipToData = false,
    skipToNextData = false,
    config = null,
  ) => {
    const payload = {
      page_number: pageNumber,
      row_offset: 0,
      row_limit: 0,
      skip_to_data: skipToData,
      fetch_next_page: skipToNextData,
    };
    setLoading(true);
    axiosPrivate
      .post(`${ExtractionService.VIEW_EXTRACTION(applicationId, docId)}`, payload, config)
      .then((response) => {
        if (response?.status === 204) {
          openSnackbar('info', skipToNextData ? Toast.NO_DATA_FOUND_NEXT_PAGE : Toast.NO_DATA_FOUND_PREVIOUS_PAGE);
        } else {
          const extractionCp = { ...response.data.data };
          dispatch(setExtractionDetails(extractionCp));

          if (skipToData) {
            dispatch(setCurrentPage(extractionCp.output_json.page_details[0].page_number));
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        if (err?.message !== 'canceled') {
          setLoading(false);
          openSnackbar('error', err?.response?.data?.message);
        }
      });
  };

  const addKeyValue = async (payload, currentPage = 1) => {
    setLoading(true);
    return axiosPrivate
      .post(`${ExtractionService.ADD_KEY_VALUES(applicationId, docId)}`, payload)
      .then(() => {
        fetchDocumentExtraction(currentPage);
        setLoading(false);
        return true; // Resolve with true when API call succeeds
      })
      .catch((err) => {
        setLoading(false);
        openSnackbar('error', err?.response?.data?.message);
        return false; // Reject with false when API call fails
      });
  };

  const addTableRows = async (tableId, payload, currentPage = 1) => {
    setLoading(true);
    return axiosPrivate
      .post(`${ExtractionService.ADD_ROWS(applicationId, docId, tableId)}`, payload)
      .then(() => {
        fetchDocumentExtraction(currentPage);
        setLoading(false);
        return true; // Resolve with true when API call succeeds
      })
      .catch((err) => {
        setLoading(false);
        openSnackbar('error', err?.response?.data?.message);
        return false; // Reject with false when API call fails
      });
  };

  const editEntities = async (payload, currentPage = 1) => {
    setLoading(true);
    return axiosPrivate
      .put(`${ExtractionService.EDIT_KEY_VALUES(applicationId, docId)}`, payload)
      .then(() => {
        fetchDocumentExtraction(currentPage);
        setLoading(false);
        return true; // Resolve with true when API call succeeds
      })
      .catch((err) => {
        setLoading(false);
        openSnackbar('error', err?.response?.data?.message);
        return false; // Reject with false when API call fails
      });
  };

  const editTableRows = async (tableId, payload, currentPage = 1) => {
    setLoading(true);
    return axiosPrivate
      .put(`${ExtractionService.EDIT_ROWS(applicationId, docId, tableId)}`, payload)
      .then(() => {
        fetchDocumentExtraction(currentPage);
        setLoading(false);
        return true; // Resolve with true when API call succeeds
      })
      .catch((err) => {
        setLoading(false);
        openSnackbar('error', err?.response?.data?.message);
        return false; // Reject with false when API call fails
      });
  };

  const deleteEntities = async (payload, currentPage = 1) => {
    setLoading(true);
    return axiosPrivate
      .delete(`${ExtractionService.DELETE_KEY_VALUES(applicationId, docId)}`, { data: payload })
      .then(() => {
        fetchDocumentExtraction(currentPage);
        setLoading(false);
        return true; // Resolve with true when API call succeeds
      })
      .catch((err) => {
        setLoading(false);
        openSnackbar('error', err?.response?.data?.message);
        return false; // Reject with false when API call fails
      });
  };

  const deleteTableRows = async (tableId, payload, currentPage = 1) => {
    setLoading(true);
    return axiosPrivate
      .delete(`${ExtractionService.DELETE_ROWS(applicationId, docId, tableId)}`, { data: payload })
      .then(() => {
        fetchDocumentExtraction(currentPage);
        setLoading(false);
        return true; // Resolve with true when API call succeeds
      })
      .catch((err) => {
        setLoading(false);
        openSnackbar('error', err?.response?.data?.message);
        return false; // Reject with false when API call fails
      });
  };

  const fetchAllBoundingBoxes = () => {
    const source = axiosCancelToken.source();
    const config = { cancelToken: source.token };

    axiosPrivate.get(ExtractionService.GET_ALL_BBOXES(applicationId, docId), config)
      .then((response) => {
        const allCoordinates = [];

        for (let pageIndex = 0; pageIndex < response.data.data.length; pageIndex += 1) {
          const pageBboxes = response.data.data[pageIndex];

          for (let bboxIndex = 0; bboxIndex < pageBboxes.bbox.length; bboxIndex += 1) {
            const bbox = pageBboxes.bbox[bboxIndex];

            allCoordinates.push({
              id: uuidv4(),
              doc_index: 0,
              page_number: +pageBboxes.page_number,
              coordinates: bbox,
            });
          }
        }

        dispatch(setAllBbox(allCoordinates));
      })
      .catch((err) => {
        openSnackbar('error', err?.response?.data?.message);
      })
      .finally(() => source.cancel());
  };

  const removeAllBoundingBoxes = () => {
    dispatch(resetAllBbox());
    dispatch(setShowAllBbox([]));
  };

  const removeDocumentExtraction = () => {
    dispatch(removeExtractionDetails());
  };

  const storeSelectedDocumentId = (documentId) => {
    dispatch(setSelectedDocumentId(documentId));
  };

  const resetSelectedDocumentId = () => {
    dispatch(removeSelectedDocumentId());
  };

  const storeCurrentPageNumber = (pageNumber) => {
    dispatch(setCurrentPage(pageNumber));
  };

  const resetCurrentPageNumber = () => {
    dispatch(resetCurrentPage());
  };

  const resetIsDataSkipped = () => {
    dispatch(setIsDataSkipped(false));
  };

  const editTableColumn = async (tableId, payload, currentPage = 1) => {
    setLoading(true);
    return axiosPrivate
      .put(`${ExtractionService.TABLE_COLUMNS(applicationId, docId, tableId)}`, payload)
      .then(() => {
        fetchDocumentExtraction(currentPage);
        setLoading(false);
        return true; // Resolve with true when API call succeeds
      })
      .catch((err) => {
        setLoading(false);
        openSnackbar('error', err?.response?.data?.message);
        return false; // Reject with false when API call fails
      });
  };

  const deleteTableColumn = async (tableId, payload, currentPage = 1) => {
    setLoading(true);
    return axiosPrivate
      .delete(`${ExtractionService.TABLE_COLUMNS(applicationId, docId, tableId)}`, { data: payload })
      .then(() => {
        fetchDocumentExtraction(currentPage);
        setLoading(false);
        return true; // Resolve with true when API call succeeds
      })
      .catch((err) => {
        setLoading(false);
        openSnackbar('error', err?.response?.data?.message);
        return false; // Reject with false when API call fails
      });
  };

  const addTableColumn = async (tableId, payload, currentPage = 1, addTable = false) => {
    setLoading(true);
    return axiosPrivate
      .post(`${ExtractionService.TABLE_COLUMNS(applicationId, docId, tableId)}`, payload)
      .then(() => {
        // don't call fetchDocumentExtraction when adding a table
        if (!addTable) {
          fetchDocumentExtraction(currentPage);
        }
        setLoading(false);
        return true; // Resolve with true when API call succeeds
      })
      .catch((err) => {
        setLoading(false);
        openSnackbar('error', err?.response?.data?.message);
        return false; // Reject with false when API call fails
      });
  };

  const addTable = async (payload) => {
    setLoading(true);
    return axiosPrivate
      .post(`${ExtractionService.ADD_TABLE(applicationId, docId)}`, payload)
      .then((response) => response.data.data.table_id)
      .catch((err) => {
        setLoading(false);
        openSnackbar('error', err?.response?.data?.message);
        return false; // Reject with false when API call fails
      });
  };

  const deleteTableCall = async (tableIds, currentPage = 1) => {
    setLoading(true);
    const errs = [];

    const promises = tableIds.map((tableID) => axiosPrivate.delete(`${ExtractionService.DELETE_TABLE(applicationId, docId, tableID)}`)
      .then((response) => response.data.status)
      .catch((err) => {
        errs.push(err?.response?.data?.message);
        return false;
      }));

    return Promise.all(promises)
      .then((results) => {
        fetchDocumentExtraction(currentPage);
        if (errs.length > 0) {
          openSnackbar('error', errs[0]);
          return false;
        }
        setLoading(false);
        return results.every((result) => result === true);
      })
      .catch((err) => {
        setLoading(false);
        openSnackbar('error', err?.response?.data?.message);
        return false;
      });
  };

  const storeEntityCount = (entityCount) => {
    dispatch(setEntityCount(entityCount));
  };

  const storeTableCount = (tableCount) => {
    dispatch(setTableCount(tableCount));
  };

  const exportDocument = (format) => {
    setLoading(true);
    axiosPrivate
      .get(
        `${ExtractionService.EXPORT_DOCUMENT(applicationId, docId, format)}`,
      )
      .then((response) => {
        const downloadUrl = response.data.data.url;
        return axiosPrivate.get(downloadUrl, { responseType: 'blob' });
      })
      .then((response) => {
        setLoading(false);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${docId}-extraction.${format}`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        openSnackbar('success', 'Document downloaded successfully');
      })
      .catch((err) => {
        setLoading(false);
        openSnackbar('error', err?.response?.data?.message);
      });
  };

  return {
    fetchDocumentExtraction,
    fetchAllBoundingBoxes,
    removeAllBoundingBoxes,
    storeSelectedDocumentId,
    removeDocumentExtraction,
    resetSelectedDocumentId,
    storeCurrentPageNumber,
    resetCurrentPageNumber,
    resetIsDataSkipped,
    loading,
    addKeyValue,
    addTableRows,
    editEntities,
    editTableRows,
    deleteEntities,
    deleteTableRows,
    editTableColumn,
    deleteTableColumn,
    addTableColumn,
    addTable,
    deleteTableCall,
    storeEntityCount,
    storeTableCount,
    exportDocument,
  };
}

export default useExtraction;
