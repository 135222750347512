import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { func, string } from 'prop-types';
import usePermissions from '../../../hooks/usePermissions';
import ButtonFilled from '../../atoms/button/ButtonFilled';
import { ApplicationStatus, Permissions } from '../../../services/constants/constants';
import CustomizedPopover from '../../atoms/popover/Popover';
import TextButton from '../../atoms/button/TextButton';
import Loader from '../loader/Loader';
import Toast from '../../../services/constants/toasters';
import classes from './CompleteReviewWithoutTimer.module.css';
import { ApplicationService } from '../../../services/constants/endpoints';
import useAxios from '../../../hooks/useAxios';
import { useSnackbar } from '../../../context/SnackbarContext';
import { isEmpty } from '../../../services/utils/Helper';
import ReviewCompletedIcon from '../../../assets/reviewCompletedIcon.svg';

function CompleteReviewWithoutTimer({ applicationId, fetchApplication }) {
  const axiosPrivate = useAxios();
  const { openSnackbar } = useSnackbar();

  const [completeReviewAnchor, setCompleteReviewAnchor] = useState(null);
  const [completingLoader, setCompletingLoader] = useState(false);
  const { checkPermission } = usePermissions();
  const { openedApplication } = useSelector((state) => state.applications);

  const handleCompleteReviewAnchor = (event) => {
    setCompleteReviewAnchor(event.currentTarget);
  };

  const completeReview = () => {
    const payload = {
      field: 'status',
      value: 'Reviewed',
    };

    setCompletingLoader(true);
    axiosPrivate.patch(`${ApplicationService.UPDATE_APPLICATION(applicationId)}`, payload)
      .then(async () => {
        fetchApplication();
        setCompletingLoader(false);
        openSnackbar('success', Toast.REVIEW_COMPLETED_SUCCESS);
      })
      .catch((error) => {
        setCompletingLoader(false);
        openSnackbar('error', error?.response?.data?.message);
      });
  };

  return (
    <>
      <Loader loading={completingLoader} />
      {!isEmpty(openedApplication) && (
        openedApplication?.status?.toLowerCase() === ApplicationStatus.REVIEWED.toLowerCase() ? (
          <div className={classes.reviewCompleteContainer}>
            <div className={classes.reviewCompleteMsg}>
              <img src={ReviewCompletedIcon} alt="check" />
              <span className={`h3Medium ${classes.reviewCompleteText}`}>Review Completed!</span>
            </div>
          </div>
        ) : (
          <>
            <ButtonFilled
              text="Complete Review"
              height="1.75rem"
              onClick={(event) => handleCompleteReviewAnchor(event)}
              borderRadius="4px 4px 4px 4px"
              disabled={!checkPermission(Permissions.EDIT_EXTRACTION)}
              defaultClass="h5Regular"
              width="max-content"
            />
            <CustomizedPopover
              anchorEl={completeReviewAnchor}
              open={Boolean(completeReviewAnchor)}
              handleClose={() => setCompleteReviewAnchor(null)}
              content={(
                <div className={classes.confirmPopoverActionContainer}>
                  <div className={classes.confirmPopoverActionLabel}>
                    <p className={`h3Regular ${classes.confirmPopupHeader}`}>Are you sure you want to complete the review ?</p>
                  </div>
                  <div className={classes.confirmPopoverAction}>
                    <TextButton
                      text="Cancel"
                      onClick={() => setCompleteReviewAnchor(null)}
                    />
                    <ButtonFilled
                      height="2rem"
                      text="Confirm"
                      onClick={() => {
                        completeReview();
                        setCompleteReviewAnchor(null);
                      }}
                    />
                  </div>
                </div>
              )}
            />
          </>
        ))}
    </>
  );
}

CompleteReviewWithoutTimer.propTypes = {
  applicationId: string.isRequired,
  fetchApplication: func.isRequired,
};

export default CompleteReviewWithoutTimer;
