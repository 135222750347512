import { ExtractionActionTypes } from '../constants/extractionActionTypes';

const initialState = {
  selectedDocumentId: '',
  extraction: null,
  currentPage: 1,
  isDataSkipped: false,
  entityCount: 0,
  tableCount: 0,
};

const extractionReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ExtractionActionTypes.SET_SELECTED_EXTRACTION:
      return { ...state, extraction: payload };

    case ExtractionActionTypes.REMOVE_SELECTED_EXTRACTION:
      return { ...state, extraction: null };

    case ExtractionActionTypes.SET_SELECTED_DOCUMENT_ID:
      return { ...state, selectedDocumentId: payload };

    case ExtractionActionTypes.REMOVE_SELECTED_DOCUMENT_ID:
      return { ...state, selectedDocumentId: '' };

    case ExtractionActionTypes.SET_EXTRACTION_CURRENT_PAGE:
      return { ...state, currentPage: payload };

    case ExtractionActionTypes.RESET_CURRENT_PAGE:
      return { ...state, currentPage: 1 };

    case ExtractionActionTypes.SET_IS_DATA_SKIPPED:
      return { ...state, isDataSkipped: payload };

    case ExtractionActionTypes.SET_ENTITY_COUNT:
      return { ...state, entityCount: payload };

    case ExtractionActionTypes.SET_TABLE_COUNT:
      return { ...state, tableCount: payload };

    case ExtractionActionTypes.RESET_EXTRACTION:
      return initialState;

    default:
      return state;
  }
};

export default extractionReducer;
