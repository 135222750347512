// importing libraries
import React, { useState } from 'react';
import {
  string, func, arrayOf, bool,
} from 'prop-types';

// importing styles
import styles from './Dropdown.module.css';

// importing components
import IconButton from '../../molecules/icon-button/IconButton';
import { titlecase } from '../../../services/utils/Helper';
import CustomizedPopover from '../popover/Popover';
import usePermissions from '../../../hooks/usePermissions';

// way to use it
// <Dropdown
//   options={options}
//   handleOptionClick={handleOptionClick}
//   defaultOption={options[0].value}
//   selectedOption={selectedOption}
//   label=""
// />

// option patterns
// const options = [
//   { key: 'abc1', value: 'Abc 1' },
// ];

// disabled options
// const disabledOptions = ['key1','key2'];

// bordertype consts
const borderSize = 'BORDER_SIZE';
const borderStyle = 'BORDER_STYLE';
const borderColor = 'BORDER_COLOR';
function Dropdown(props) {
  const {
    defaultClass, disabled, selectBG, cursor, selectHeight, selectWidth,
    selectBorder, activeSelectBorder, selectTextColor,
    optionsHeight, optionsWidth,
    options, handleOptionClick, defaultOption, selectedOption, label,
    anchorOriginHorizontal, transformOriginHorizontal, iconField,
    disabledOptions, isTimerDependent,
  } = props;
  const [isOpenAnchor, setIsOpenAnchor] = useState(null);

  const { shouldDisableHitl } = usePermissions();

  const shouldDisable = () => {
    if (isTimerDependent) {
      return disabled || shouldDisableHitl();
    }
    return disabled;
  };

  const splitBorder = (border, borderType) => {
    const borderSplit = border.split(' ');
    if (borderType === borderSize) {
      return borderSplit[0];
    }
    if (borderType === borderStyle) {
      return borderSplit[1];
    }
    if (borderType === borderColor) {
      return borderSplit[2];
    }
    return border;
  };

  const getOptionValue = (value) => {
    const splittedValue = value.split('/n');
    if (splittedValue.length >= 2) {
      return (
        <span className={styles.splittedValue}>
          {splittedValue[0]}
          <br />
          {splittedValue[1]}
        </span>
      );
    }
    return splittedValue[0];
  };

  const handleClosePopover = () => {
    setIsOpenAnchor(null);
  };

  return (
    <div
      className={styles.responsiveCustomSelect}
      style={{ width: selectWidth, height: selectHeight, opacity: shouldDisable() ? 0.5 : 1 }}
    >
      {label && <label htmlFor="label">{label}</label>}
      <div
        onClick={(event) => !shouldDisable() && setIsOpenAnchor(event.currentTarget)}
        className={`${styles.selectedOption} ${isOpenAnchor ? 'open' : ''}`}
        style={{
          backgroundColor: `var(--${selectBG})`,
          cursor,
          borderWidth: isOpenAnchor
            ? splitBorder(activeSelectBorder, borderSize)
            : splitBorder(selectBorder, borderSize),
          borderStyle: isOpenAnchor
            ? splitBorder(activeSelectBorder, borderStyle)
            : splitBorder(selectBorder, borderStyle),
          borderColor: isOpenAnchor
            ? `var(--${splitBorder(activeSelectBorder, borderColor)})`
            : `var(--${splitBorder(selectBorder, borderColor)})`,
        }}
        role="button"
        onKeyDown={() => { }}
        tabIndex={0}
      >
        <p
          className={`${defaultClass} ${styles.selectText}`}
          style={{ color: `var(--${selectTextColor})` }}
        >
          {iconField ? (
            <IconButton
              iconName={iconField}
              iconHeight={15}
              iconWidth={15}
              iconColor={isOpenAnchor ? 'defaultblue' : 'infoTextColor'}
            />
          ) : titlecase(selectedOption || defaultOption)}
        </p>
        {isOpenAnchor ? (
          <div>
            <IconButton
              iconName="dropdownArrowUpIcon"
              iconHeight={5}
              iconWidth={8}
            />
          </div>
        ) : (
          <div>
            <IconButton
              iconName="dropdownArrowDownIcon"
              iconHeight={5}
              iconWidth={8}
            />
          </div>
        )}
      </div>

      <CustomizedPopover
        anchorEl={isOpenAnchor}
        open={Boolean(isOpenAnchor)}
        handleClose={handleClosePopover}
        anchorOriginHorizontal={anchorOriginHorizontal}
        transformOriginHorizontal={transformOriginHorizontal}
        content={(
          <div
            className={`${styles.options} ${isOpenAnchor ? styles.fadeIn : ''}`}
            style={{
              height: optionsHeight,
              width: optionsWidth || selectWidth,
              opacity: shouldDisable() ? 0.5 : 1,
            }}
          >
            {options.map((option) => (
              <div
                key={option.key}
                onClick={() => {
                  if (!shouldDisable() && !disabledOptions.includes(option.key)) {
                    handleOptionClick(option.key);
                    setIsOpenAnchor(null);
                  }
                }}
                className={`${styles.option} ${defaultClass}`}
                style={{
                  height: '',
                  background: selectedOption?.toLowerCase() === option.value?.toLowerCase() && 'var(--blueGrayLightDark)',
                  cursor: (disabledOptions && disabledOptions.includes(option.key)) ? 'not-allowed' : 'pointer',
                }}
                aria-hidden="true"
              >
                <span className={styles.optionValue}>
                  {getOptionValue(option.value)}
                </span>
              </div>
            ))}
          </div>
)}
      />

    </div>
  );
}

Dropdown.propTypes = {
  options: arrayOf(Object).isRequired,
  handleOptionClick: func.isRequired,
  disabled: bool,
  defaultOption: string,
  selectedOption: string,
  label: string,
  selectBG: string,
  cursor: string,
  selectHeight: string,
  selectWidth: string,
  optionsHeight: string,
  selectBorder: string,
  activeSelectBorder: string,
  selectTextColor: string,
  defaultClass: string,
  optionsWidth: string,
  anchorOriginHorizontal: string,
  transformOriginHorizontal: string,
  iconField: string,
  disabledOptions: arrayOf(string),
  isTimerDependent: bool,
};

Dropdown.defaultProps = {
  defaultOption: 'Select an option',
  disabled: false,
  selectedOption: '',
  label: '',
  selectBG: 'white',
  cursor: 'pointer',
  selectHeight: '29px',
  selectWidth: '202px',
  // optionsHeight: '5rem',
  optionsHeight: 'auto',
  selectBorder: '0.5px solid textFieldOutlineGray',
  activeSelectBorder: '0.5px solid blueDefault',
  selectTextColor: 'darkGray',
  defaultClass: 'h5Regular',
  optionsWidth: '',
  anchorOriginHorizontal: 'left',
  transformOriginHorizontal: 'left',
  iconField: '',
  disabledOptions: [],
  isTimerDependent: false,
};

export default Dropdown;
