export const BoundingBoxActionTypes = {
  SET_ACTIVE_DOCUMENT_INDEX: 'SET_ACTIVE_DOCUMENT_INDEX',
  SET_BBOX: 'SET_BBOX',
  RESET_BBOX: 'RESET_BBOX',
  SET_ALL_BBOX: 'SET_ALL_BBOX',
  RESET_ALL_BBOX: 'RESET_ALL_BBOX',
  SET_SHOW_ALL_BBOX: 'SET_SHOW_ALL_BBOX',
  SET_ANNOTATIONS: 'SET_ANNOTATIONS',
  RESET_ANNOTATIONS: 'RESET_ANNOTATIONS',
  RESET_BOUNDING_BOX: 'RESET_BOUNDING_BOX',
};
