import React, { useState, useEffect } from 'react';
import './MainLayout.css';
import { Outlet } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Sidebar from './components/sidebar/Sidebar';
// import IdleSessionTimeout from
// '../../components/molecules/idle-session-timeout/IdleSessionTimeout';
import { useUserAuth } from '../../context/UserAuthContext';
import usePermissions from '../../hooks/usePermissions';
import useAxios from '../../hooks/useAxios';
import { ServiceCall } from '../../services/constants/endpoints';
import {
  setAppStatusList, setCarrierList, setClassificationList, setLineOfBusinessList, setSourceList,
  setSubClassificationList, setUsersList, setSystemLoading,
} from '../../redux/actions/systemActions';
import Loader from '../../components/molecules/loader/Loader';
import { areWeInsideIframe } from '../../services/utils/Helper';

function MainLayout() {
  const [loadingSystemCall, setLoadingSystemCall] = useState(false);
  const [loadingPermissionsCall, setLoadingPermissionsCall] = useState(false);
  const { user, updateUser } = useUserAuth();
  const { fetchUserPermissions } = usePermissions();
  const axiosPrivate = useAxios();
  const dispatch = useDispatch();

  const convertToKeyValue = (list, isEmailRequired = true, isSubClfList = false) => {
    const convertList = [];
    if (isEmailRequired) {
      if (list.length) {
        for (let usrIndex = 0; usrIndex < list.length; usrIndex += 1) {
          convertList.push({
            key: list[usrIndex].id,
            value: `${list[usrIndex].name}/n${list[usrIndex].email_id}`,
            displayName: list[usrIndex].name,
            email: list[usrIndex].email_id,
          });
        }
        return convertList;
      }
      return [];
    }
    if (list.length) {
      for (let i = 0; i < list.length; i += 1) {
        if (isSubClfList) {
          convertList.push({
            id: list[i].id,
            clfId: list[i].classification_id,
            key: list[i].display_name,
            value: list[i].display_name,
          });
        } else {
          convertList.push({
            id: list[i].id,
            key: list[i].display_name,
            value: list[i].display_name,
          });
        }
      }
      return convertList;
    }
    return convertList;
  };

  // to fetch users list to assign application
  const fetchUsers = () => axiosPrivate.get(`${ServiceCall.GET_SYSTEM_CALL('users')}`)
    .then(async (response) => {
      const convertedUserList = convertToKeyValue(response.data.values);
      dispatch(setUsersList(convertedUserList));

      let userId = '';

      if (areWeInsideIframe()) {
        userId = user.id;
        const userEmail = convertedUserList.find((usr) => usr.key === userId)?.email ?? '';
        updateUser(userEmail, user.token, user.refreshToken, '', Date.now(), user.id);
      } else {
        userId = convertedUserList.find((usr) => usr.email === user.email)?.key ?? '';
      }

      setLoadingPermissionsCall(true);
      await fetchUserPermissions(userId);
      setLoadingPermissionsCall(false);
    })
    .catch(() => { });

  // to fetch ingestion sources list
  const fetchSourceList = () => axiosPrivate.get(`${ServiceCall.GET_SYSTEM_CALL('source')}`)
    .then((response) => {
      const convertedSourceList = convertToKeyValue(response.data.values, false);
      dispatch(setSourceList(convertedSourceList));
    })
    .catch(() => { });

  // to fetch application statuses list
  const fetchAppStatusList = () => axiosPrivate.get(`${ServiceCall.GET_SYSTEM_CALL('status_applications')}`)
    .then((response) => {
      const convertedAppStatusList = convertToKeyValue(response.data.values, false);
      dispatch(setAppStatusList(convertedAppStatusList));
    })
    .catch(() => { });

  // to fetch classification list
  const fetchClassification = () => axiosPrivate.get(`${ServiceCall.GET_SYSTEM_CALL('classification')}`)
    .then((response) => {
      const convertedClassificationList = convertToKeyValue(response.data.values, false);
      dispatch(setClassificationList(convertedClassificationList));
    })
    .catch(() => { });

  // to fetch sub-classification list
  const fetchSubClassification = () => axiosPrivate.get(`${ServiceCall.GET_SYSTEM_CALL('sub_classification')}`)
    .then((response) => {
      const convertedSubClassificationList = convertToKeyValue(response.data.values, false, true);
      dispatch(setSubClassificationList(convertedSubClassificationList));
    })
    .catch(() => { });

  // to fetch Line of Business list
  const fetchLineOfBusiness = () => axiosPrivate.get(`${ServiceCall.GET_SYSTEM_CALL('line_of_business')}`)
    .then((response) => {
      const convertedClassificationList = convertToKeyValue(response.data.values, false);
      dispatch(setLineOfBusinessList(convertedClassificationList));
    })
    .catch(() => { });

  // to fetch Carrier list
  const fetchCarrierList = () => axiosPrivate.get(`${ServiceCall.GET_SYSTEM_CALL('carrier')}`)
    .then((response) => {
      const convertedCarrierList = convertToKeyValue(response.data.values, false);
      dispatch(setCarrierList(convertedCarrierList));
    })
    .catch(() => { });

  useEffect(() => {
    setLoadingSystemCall(true);
    dispatch(setSystemLoading(true));
    Promise.all([
      fetchUsers(),
      fetchSourceList(),
      fetchAppStatusList(),
      fetchClassification(),
      fetchSubClassification(),
      fetchLineOfBusiness(),
      fetchCarrierList(),
    ])
      .then(() => {
        setLoadingSystemCall(false);
        dispatch(setSystemLoading(false));
      })
      .catch(() => {
        setLoadingSystemCall(false);
        dispatch(setSystemLoading(false));
      });
  }, []);

  return (
    <>
      <Loader loading={loadingSystemCall || loadingPermissionsCall} />
      {!areWeInsideIframe() && (
        <Sidebar />
      )}
      <section className={areWeInsideIframe() ? 'home-section-iframe' : 'home-section'}>
        <div id="mt-router-outlet-main">
          <Outlet />
        </div>
      </section>
      {/* <IdleSessionTimeout /> */}
    </>
  );
}

export default MainLayout;
