// importing libraries
import React from 'react';
import { bool, func, string } from 'prop-types';

// importing styles
import classes from './Checkbox.module.css';
import IconButton from '../../molecules/icon-button/IconButton';
import usePermissions from '../../../hooks/usePermissions';

function Checkbox(props) {
  const {
    label, isChecked, handleChange, fontSize, color,
    isDisabled, isTimerDependent,
  } = props;

  const { shouldDisableHitl } = usePermissions();

  const shouldDisable = () => {
    if (isTimerDependent) {
      return isDisabled || shouldDisableHitl();
    }
    return isDisabled;
  };

  return (
    <div className={classes.checkbox} onClick={() => handleChange(!isChecked)} aria-hidden>
      <span>
        {
          isChecked
            ? (
              <IconButton
                iconName="checkedCheckboxIcon"
                iconWidth="12"
                iconHeight="12"
                handleClick={() => handleChange(!isChecked)}
                isDisabled={shouldDisable()}
              />
            )
            : (
              <IconButton
                iconName="unCheckedCheckboxIcon"
                iconWidth="12"
                iconHeight="12"
                handleClick={() => handleChange(!isChecked)}
                isDisabled={shouldDisable()}
              />
            )
        }
      </span>
      <span style={{ fontSize, color: `var(--${color})` }}>{label}</span>
    </div>
  );
}

Checkbox.propTypes = {
  label: string,
  isChecked: bool.isRequired,
  handleChange: func.isRequired,
  fontSize: string,
  color: string,
  isDisabled: bool,
  isTimerDependent: bool,
};

Checkbox.defaultProps = {
  label: '',
  fontSize: '0.75rem',
  color: 'darkBlue',
  isDisabled: false,
  isTimerDependent: false,
};

export default Checkbox;
