import { BoundingBoxActionTypes } from '../constants/boundingBoxActionTypes';

const initialState = {
  activeDocumentIndex: 0,
  bbCoordinate: null,
  allBoundingBoxes: [],
  showAllBB: [],
  annotations: [],
};

const boundingBoxReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case BoundingBoxActionTypes.SET_ACTIVE_DOCUMENT_INDEX:
      return { ...state, activeDocumentIndex: payload };

    case BoundingBoxActionTypes.SET_BBOX:
      return { ...state, bbCoordinate: payload };

    case BoundingBoxActionTypes.RESET_BBOX:
      return { ...state, bbCoordinate: null };

    case BoundingBoxActionTypes.SET_ALL_BBOX:
      return { ...state, allBoundingBoxes: payload };

    case BoundingBoxActionTypes.RESET_ALL_BBOX:
      return { ...state, allBoundingBoxes: [] };

    case BoundingBoxActionTypes.SET_SHOW_ALL_BBOX:
      return { ...state, showAllBB: payload };

    case BoundingBoxActionTypes.SET_ANNOTATIONS:
      return { ...state, annotations: payload };

    case BoundingBoxActionTypes.RESET_ANNOTATIONS:
      return { ...state, annotations: [] };

    case BoundingBoxActionTypes.RESET_BOUNDING_BOX:
      return initialState;

    default:
      return state;
  }
};

export default boundingBoxReducer;
