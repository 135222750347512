import React, { useEffect, useState } from 'react';
import {
  Outlet, Navigate, useLocation, useNavigate,
  useSearchParams, useParams,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useUserAuth } from '../context/UserAuthContext';
import usePermissions from '../hooks/usePermissions';
import { routePermissions } from '../services/constants/constants';
import { areWeInsideIframe } from '../services/utils/Helper';

function ProtectedOutlet() {
  const [areTokensLoaded, setAreTokensLoaded] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const { applicationId } = useParams();
  const [searchParams] = useSearchParams();

  const { user, removeUser, updateUser } = useUserAuth();
  const { checkPermission } = usePermissions();

  const { permissions } = useSelector((state) => state.system);

  useEffect(() => {
    if (permissions?.length > 0) {
      const requiredPermissions = routePermissions[location.pathname];
      // if no specific permission is required for the route, allow access
      if (requiredPermissions) {
        const hasRequiredPermissions = requiredPermissions
          .every((permission) => checkPermission(permission));

        if (!hasRequiredPermissions) {
          navigate(location?.state ? location.state.from.pathname + location.state.from.search : '/home/application-dashboard', { replace: true });
        }
      }
    }
  }, [permissions, location.pathname]);

  useEffect(() => {
    if (areWeInsideIframe() && !areTokensLoaded) {
      const clientId = searchParams.get('clientId');
      const token = searchParams.get('token');
      const refreshToken = searchParams.get('refreshToken');

      if (clientId && token && refreshToken) {
        updateUser(
          '',
          token,
          refreshToken,
          '',
          Date.now(),
          clientId,
        );
        setAreTokensLoaded(true);

        const docIds = searchParams.get('docs');
        const route = `/home/applications/${applicationId}/comparison?docs=${docIds}`;
        navigate(route);
      }
    }
  }, [searchParams]);

  useEffect(() => {
    const currentTime = Date.now();
    const IDLE_TIME_LIMIT = process.env.REACT_APP_IDLE_TIMEOUT * 60;

    if (user && user.loggedInTime) {
      const difference = ((currentTime - user.loggedInTime) / 60000);
      if (difference >= IDLE_TIME_LIMIT) {
        removeUser();
        navigate('/login', { state: { from: location } });
      }
    }
  }, [user]);

  return (
    (user && user.token) ? <Outlet /> : <Navigate to="/login" state={{ from: location }} />
  );
}

export default ProtectedOutlet;
