import React from 'react';
import { Tooltip } from '@mui/material';
import PropTypes from 'prop-types';

// position can be "top", "right", "left" and "bottom" (default)

function CustomizedTooltip(props) {
  const {
    text, children, position, defaultClass,
  } = props;

  return (
    <Tooltip
      title={(
        <span className={defaultClass}>{text}</span>
      )}
      placement={position}
      arrow
      disableInteractive={false}
      componentsProps={{
        tooltip: {
          sx: {
            visibility: text === '' ? 'hidden' : '',
            bgcolor: 'var(--darkBlue)',
            '& .MuiTooltip-arrow': {
              color: 'var(--darkBlue)',
            },
          },
        },
        popper: {
          sx: {
            zIndex: `${3000}!important`,
          },
        },
      }}
    >
      <div>
        {children}
      </div>
    </Tooltip>
  );
}

CustomizedTooltip.propTypes = {
  text: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  position: PropTypes.string,
  defaultClass: PropTypes.string,
};

CustomizedTooltip.defaultProps = {
  position: 'bottom',
  defaultClass: 'h5Regular',
};

export default CustomizedTooltip;
